import React , { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Nav, Tab } from 'react-bootstrap';

import config from '../../../config';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ShopsTable, ShopDetail } from './components/index';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopMain(props) {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ key, setKey ] = useState('pending');
    const [ shoplist, setShoplist ] = useState([]);
    const [ shopinfo, setShopinfo ] = useState({});

    useEffect(() => {
        let param = {
            token: user.token,
            filters: {'status' : key}
        };
        getShopList(param);
    }, [key]);

    const getShopList = (param) => {
        setLoading(true);
        setShoplist([])
        fetch(config.crypto_url + 'admin/shop/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setShoplist(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (shopid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/shop/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: shopid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setShopinfo(result);
                    setPage('detail');
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnApproved = (shopid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/shop/approve', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: shopid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            getShopList({
                                token: user.token,
                                filters: {'status' : key}
                            })
                        }
                    });
                    setPage('main');
                    setShopinfo({})
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnRejected = (shopid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/shop/rejected', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: shopid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            getShopList({
                                token: user.token,
                                filters: {'status' : key}
                            })
                        }
                    });
                    setPage('main');
                    setShopinfo({})
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const btnPublish = (shopid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/shop/toggle-publish', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: shopid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        html: 'ดำเนินการเรียบร้อย',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    })
                    setPage('main');
                    setShopinfo({})
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <div className={'card card-custom card-stretch gutter-b'}>
                            <div className='card-header border-0 pt-10'>
                                <h1 className='card-title align-items-start flex-column'>
                                    <span className='card-label font-weight-bolder text-dark'>รายการร้านค้า</span>
                                </h1>
                                <div className='card-toolbar'>
                                    <Tab.Container defaultActiveKey={key} onSelect={_eventKey => setKey(_eventKey)}>
                                        <div className='row'>
                                            <div className='col-12 p-0'>
                                                <Nav className='nav nav-pills nav-pills-sm nav-dark-75'>
                                                    <Nav.Item className='nav-item'>
                                                        <Nav.Link eventKey='pending'>รอดำเนินการ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='nav-item'>
                                                        <Nav.Link eventKey='approved'>อนุมัติ</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='nav-item'>
                                                        <Nav.Link eventKey='rejected'>ปฏิเสธ</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                            <ShopsTable shoplist={shoplist} btnView={btnView}/>
                        </div>
                    ) : page === 'detail' ? (
                        <ShopDetail
                            setPage={setPage}
                            shopinfo={shopinfo}
                            setShopinfo={setShopinfo}
                            btnApproved={btnApproved}
                            btnRejected={btnRejected}
                            btnPublish={btnPublish}
                        />
                    ) : null}
                </div>
            )}
        </div>
    )
}