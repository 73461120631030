import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import Moment from 'moment';

import 'moment/locale/th';

import $ from 'jquery';

import Select, { components } from 'react-select';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { QRCode } from 'react-qrcode-logo';

import html2canvas from 'html2canvas';

import { OrdersTable } from './components/Table';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const controlStyles = {
    borderRadius: '0.85rem',
    // padding: "5px",
    // background: "#F3F6F9",
    // color: "#3F4254",
    border: '1px solid #E4E6EF'
    // borderStyle: "solid"
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
};

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2022,
    month: 1,
    day: 1
};

// set('pending','paid','cancel','expired','shipping','completed')
const statusList = [
    {
        label: 'รอการชำระเงิน',
        value: 'pending'
    },
    {
        label: 'ชำระเงินแล้ว',
        value: 'paid'
    },
    {
        label: 'ยกเลิก',
        value: 'cancel'
    },
    {
        label: 'หมดอายุ',
        value: 'expired'
    },
    {
        label: 'กำลังจัดส่ง',
        value: 'shipping'
    },
    {
        label: 'จัดส่งแล้ว',
        value: 'shipped'
    },
    {
        label: 'ยืนยันรายการ',
        value: 'completed'
    }
];

export function OrdersMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ orderlist, setOrderlist ] = useState({});
    const [ oid, setOid ] = useState('');
    const [ odate, setODate ] = useState({
        from: null,
        to: null
    });
    const [ pdate, setPDate ] = useState({
        from: null,
        to: null
    });
    const [ status, setStatus ] = useState('');
    // const [ qrcode, setQrcode ] = useState('');
    // const [ ref1, setRef1 ] = useState('');
    // const [ ref2, setRef2 ] = useState('');

    const [ showModal, setShow ] = useState(false);
    const [ orderinfo, setOrderInfo ] = useState(null);
    const [ price, setPrice ] = useState(null);
    const [ ship, setShip ] = useState(null);
    const [ coupon, setCoupon ] = useState(null);
    const [ total, setTotal ] = useState(null);

    const [ shoplist, setShopList ] = useState([]);
    const [ shopname, setShopName ] = useState(null);
    const [ shopIDList, setShopIDList ] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = (id='') => {
        // setQrcode(code);
        // setRef1(refcode1);
        // setRef2(refcode2);
        // setShow(true);
        btnView(id);
        $(document).ready(function() {
            $('#react-qrcode-logo').css('border', '2px solid');
        });
    };

    const selectStatus = (item) => {
        if (item) {
            setStatus(item);
        } else {
            setStatus('');
        }
    };

    const emptyODate = () => {
        setODate({
            from: null,
            to: null
        });
    };

    const emptyPDate = () => {
        setPDate({
            from: null,
            to: null
        });
    };

    const OdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    odate.from && odate.to ? (
                        odate.from.year +
                        '/' +
                        ('0' + odate.from.month).slice(-2) +
                        '/' +
                        ('0' + odate.from.day).slice(-2) +
                        ' - ' +
                        odate.to.year +
                        '/' +
                        ('0' + odate.to.month).slice(-2) +
                        '/' +
                        ('0' + odate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${odate.from && odate.to ? ' rounded-right-0' : ''}`}
            />
            {odate.from &&
            odate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyODate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    const PdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    pdate.from && pdate.to ? (
                        pdate.from.year +
                        '/' +
                        ('0' + pdate.from.month).slice(-2) +
                        '/' +
                        ('0' + pdate.from.day).slice(-2) +
                        ' - ' +
                        pdate.to.year +
                        '/' +
                        ('0' + pdate.to.month).slice(-2) +
                        '/' +
                        ('0' + pdate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${pdate.from && pdate.to ? ' rounded-right-0' : ''}`}
            />
            {pdate.from &&
            pdate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyPDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    async function getOrderList(output='') {
        let param = {
            token : user.token,
            filters : {}
        }
        if (oid !== '') {
            param['filters']['id'] = oid;
        }
        if (odate.from !== null && odate.to !== null) {
            param['filters']['order_date'] = {
                start : odate.from.year + '-' + ('0' + odate.from.month).slice(-2) + '-' + ('0' + odate.from.day).slice(-2),
                end : odate.to.year + '-' + ('0' + odate.to.month).slice(-2) + '-' + ('0' + odate.to.day).slice(-2)
            };
        }
        if (pdate.from !== null && pdate.to !== null) {
            param['filters']['order_date'] = {
                start : pdate.from.year + '-' + ('0' + pdate.from.month).slice(-2) + '-' + ('0' + pdate.from.day).slice(-2),
                end : pdate.to.year + '-' + ('0' + pdate.to.month).slice(-2) + '-' + ('0' + pdate.to.day).slice(-2)
            };
        }
        if (status !== '') {
            param['filters']['status'] = status.value;
        }
        if (shopIDList.length > 0) {
            param['filters']['shop_id'] = shopIDList
        }
        if (output !== '') {
            param['output'] = 'base64_excel';
        }
        setOrderlist({});
        setLoading(true);
        return fetch(config.crypto_url + 'admin/order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (output === 'export') {
                    return result
                } else {
                    setOrderlist(result);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnSearch = () => {
        getOrderList();
        setShopList([]);
        setShopIDList([]);
    };

    async function btnExport() {
        const result = await getOrderList('export');
        if (result) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.setAttribute('type', 'hidden');
            link.href = 'data:text/plain;base64,' + result.base64;
            let filename = 'orderReport';
            link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.xlsx`;
            link.click();
            document.body.removeChild(link)
        };
    };

    const btnView = (orderid) => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                id: orderid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setShow(true);
                    let price = 0
                    result.info.items.filter((first) => first.item_type === 'product').map((item) => {
                        price += (parseFloat(item.gross_price) * parseFloat(item.quantity))
                    });
                    let ship = result.info.items.filter((second) => second.item_type === 'shipping').map((sp) => {
                        return parseFloat(sp.gross_price)
                    });
                    let cp = result.info.items.filter((third) => third.item_type === 'coupon').map((cou) => {
                        return parseFloat(cou.discount)
                    });
                    let total = parseFloat(result.info.total_gross_price) + parseFloat(result.info.total_vat)
                    setPrice(price);
                    setShip(ship);
                    if (cp.length > 0) {
                        setCoupon(cp);
                    };
                    setTotal(total);
                    setOrderInfo(result.info);
                } else {
                    setShow(false);
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const getShopList = () => {
        setLoading(true);
        fetch(config.crypto_url + 'admin/shop/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                search: shopname
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderlist({});
                if (result.responsecode === 0) {
                    setShopList(result.info);
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const handleSelectShop = (shopID) => {
        if (shopIDList.includes(shopID)) {
            const index = shopIDList.indexOf(shopID);
            shopIDList.splice(index, 1)
        } else {
            setShopIDList([
                ...shopIDList,
                shopID
            ])
        }
    };

    // const handleDownload = () => {
    //     let d = new Date();
    //     html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
    //         const link = document.createElement('a');
    //         link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
    //         link.href = canvas.toDataURL();
    //         link.click();
    //     });
    // };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-body'>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-2 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>ชื่อร้านค้า</label>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-8 form-group'>
                                        <div className='form-group mb-0'>
                                            <input
                                                type='search'
                                                className='form-control rounded-lg'
                                                value={shopname}
                                                onChange={(e) => setShopName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-2 form-group'>
                                        <div className='form-group mb-0'>
                                            <button
                                                type='button'
                                                onClick={getShopList}
                                                className='btn btn-sm btn-primary rounded-lg'
                                            >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                            </button>
                                        </div>
                                    </div>
                                    {shoplist.length > 0 ? 
                                        shoplist.map((shop) => (
                                            <div className='col-12 col-md-3 form-group'>
                                                <div className='form-group mb-0'>
                                                    <input
                                                        type='checkbox'
                                                        className='rounded-lg'
                                                        id={shop.id}
                                                        onClick={(e) => handleSelectShop(e.target.id)}
                                                    />
                                                    &nbsp;
                                                    <label>{shop.name}</label>
                                                </div>
                                            </div>
                                        ))
                                    :''}
                                </div>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>หมายเลขรายการสั่งซื้อ</label>
                                            <input
                                                type='text'
                                                className='form-control rounded-lg'
                                                value={oid}
                                                onChange={(e) => setOid(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่สั่งซื้อ</label>
                                            <DatePicker
                                                value={odate}
                                                onChange={setODate}
                                                renderInput={OdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='odate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่ชำระเงิน</label>
                                            <DatePicker
                                                value={pdate}
                                                onChange={setPDate}
                                                renderInput={PdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='pdate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>สถานะรายการ</label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={status}
                                                options={statusList}
                                                placeholder='เลือก'
                                                id='dealerposition'
                                                name='position'
                                                onChange={selectStatus}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                        <br/>
                                        <button
                                            type='button'
                                            onClick={btnSearch}
                                            className='btn btn-sm btn-primary rounded-lg'
                                        >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrdersTable orderlist={orderlist} handleShow={handleShow} btnExport={btnExport}/>
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='lg' centered aria-labelledby='order-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='order-modal' className='col-11 p-0'>
                        รายการสั่งซื้อ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {orderinfo !== null ? (
                        <div className='row d-flex justify-content-center'>
                            <h4 className='col-12 text-right'>#{orderinfo.id}</h4>
                            <h6 className='col-12 text-right'>วันที่สั่งซื้อ {orderinfo.create_date}</h6>
                            <div className='row col-sm-12 d-flex pt-5 m-0'>
                                <div className='col-6'>
                                    <h6>ที่อยู่ผู้ซื้อ</h6>
                                    <label>
                                        {orderinfo.user_address.contact_firstname} &nbsp;
                                        {orderinfo.user_address.contact_lastname}
                                    </label>
                                    <br/>
                                    <label>{orderinfo.user_address.contact_phonenumber}</label>
                                    <br/>
                                    <label>
                                        {orderinfo.user_address.address1} &nbsp;
                                        {orderinfo.user_address.district} &nbsp;
                                        {orderinfo.user_address.amphur} &nbsp;
                                        {orderinfo.user_address.province} &nbsp;
                                        {orderinfo.user_address.postalcode}
                                    </label>
                                </div>
                                <div className='col-6'>
                                    <h6>ที่อยู่ร้านค้า</h6>
                                    <label>
                                        {orderinfo.shop_address.contact_firstname} &nbsp;
                                        {orderinfo.shop_address.contact_lastname}
                                    </label>
                                    <br/>
                                    <label>{orderinfo.shop_address.contact_phonenumber}</label>
                                    <br/>
                                    <label>
                                        {orderinfo.shop_address.address1} &nbsp;
                                        {orderinfo.shop_address.district} &nbsp;
                                        {orderinfo.shop_address.amphur} &nbsp;
                                        {orderinfo.shop_address.province} &nbsp;
                                        {orderinfo.shop_address.postalcode}
                                    </label>
                                </div>
                                <hr className='col-12 p-0'/>
                                <div className='col-6'>
                                    <h6>ที่อยู่จัดส่ง</h6>
                                    <label>
                                        {orderinfo.shipping_address.contact_firstname} &nbsp;
                                        {orderinfo.shipping_address.contact_lastname}
                                    </label>
                                    <br/>
                                    <label>{orderinfo.shipping_address.contact_phonenumber}</label>
                                    <br/>
                                    <label>
                                        {orderinfo.shipping_address.address1} &nbsp;
                                        {orderinfo.shipping_address.district} &nbsp;
                                        {orderinfo.shipping_address.amphur} &nbsp;
                                        {orderinfo.shipping_address.province} &nbsp;
                                        {orderinfo.shipping_address.postalcode}
                                    </label>
                                </div>
                            </div>
                            <table id='order-detail-table' className='table table-striped table-bordered'>
                                <thead>
                                    <tr>
                                        <th>สินค้า</th>
                                        <th>ราคา</th>
                                        <th>ภาษีมูลค่าเพิ่ม</th>
                                        <th>ยอดรวม</th>
                                        <th>จำนวน</th>
                                        <th>หมายเลขพัสดุ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderinfo.items.filter((order) => order.item_type === 'product').map((product) => (
                                        <tr>
                                            <td>{product.item_name}</td>
                                            <td>{product.gross_price}</td>
                                            <td>{product.vat}</td>
                                            <td>{(parseFloat(product.gross_price) + parseFloat(product.vat))}</td>
                                            <td>{product.quantity}</td>
                                            <td>{product.tracking_id}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='row col-sm-12 d-flex pt-5 m-0'>
                                <div className='col-6'>
                                    <QRCode
                                        value={orderinfo.qr}
                                        size={200}
                                        eyeRadius={5}
                                        logoImage={toAbsoluteUrl('/media/logos/favi.png')}
                                        logoWidth={65}
                                        // style={`border: 2px solid #000000`}
                                    />
                                </div>
                                <div className='col-6'>
                                    <label className='col-12 text-right'>ราคา : ฿{price}</label>
                                    <label className='col-12 text-right'>ภาษีมูลค่าเพิ่ม : ฿{orderinfo.total_vat}</label>
                                    <label className='col-12 text-right'>ค่าขนส่ง : ฿{ship !== null ? ship : 0}</label>
                                    <label className='col-12 text-right'>คูปอง : ฿{coupon !== null ? coupon : 0}</label>
                                    <h5 className='col-12 text-right'>ยอดรวม {total}</h5>
                                </div>
                            </div>
                            {/* <div className='col-sm-12 d-flex justify-content-center'>
                                <QRCode
                                    value={qrcode}
                                    size={256}
                                    eyeRadius={10}
                                    logoImage={toAbsoluteUrl('/media/logos/favi.png')}
                                    logoWidth={100}
                                    // style={`border: 2px solid #000000`}
                                />
                            </div>
                            <div className='row col-sm-12 d-flex pt-5 m-0'>
                                <p className='col-12 text-center'>รหัสคิวอาร์โค้ด1 : {ref1}</p>
                                <p className='col-12 text-center'>รหัสคิวอาร์โค้ด2 : {ref2}</p>
                            </div>
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                    <i className='fas fa-save' /> บันทึกคิวอาร์โค้ด
                                </button>
                            </div> */}
                        </div>
                    ) : '' }
                </Modal.Body>
            </Modal>
        </div>
    );
}
