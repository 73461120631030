import React, { useState } from 'react';

import Swal from 'sweetalert2/dist/sweetalert2.js';

// import InputMask from 'react-input-mask';

// import Moment from 'moment';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function CustomersEdit(props) {
    const [ firstname, setFirstname ] = useState(null);
    const [ lastname, setLastname ] = useState(null);
    // const [ nationality, setNationality ] = useState(null);
    const [ personalid, setPersonalid ] = useState(null);
    const [ contactnumber, setContactnumber ] = useState(null);
    // const [ birthdate, setBirthdate ] = useState(null);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    const btnUpdate = () => {
        let param = {};
        if (firstname !== null) {
            param['first_name'] = firstname;
        }
        if (lastname !== null) {
            param['last_name'] = lastname;
        }
        if (contactnumber !== null) {
            param['contact_number'] = contactnumber;
        }
        if (personalid !== null) {
            param['personal_id'] = personalid;
        }
        console.log(param);
        if (Object.keys(param).length <= 0) {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'ไม่สามารถดำเนินการได้เนื่องจากไม่มีการเปลี่ยนแปลงข้อมูล',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        props.btnUpdate(props.info.id.toString(), param);
    };
    return (
        <div className='row'>
            <div className='col-12 p-0'>
                <div className='row m-0'>
                    <div className='col-12 col-md-6 form-group'>
                        <label>ชื่อ</label>
                        <input
                            type='text'
                            className='form-control rounded-lg'
                            name='firstname'
                            defaultValue={props.info.first_name}
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <label>นามสกุล</label>
                        <input
                            type='text'
                            className='form-control rounded-lg'
                            name='lastname'
                            defaultValue={props.info.last_name}
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </div>
                    {/* <div className='col-12 col-md-6 form-group'>
                        <label>ประเภทข้อมูลประจำตัว</label>
                        <div className='radio-inline form-control border-0'>
                            <label className='radio radio-primary'>
                                <input
                                    type='radio'
                                    name='nationality'
                                    checked={props.info.id_type === '1' || nationality === '1'}
                                    onChange={() => setNationality('1')}
                                />
                                <span />
                                ไทย
                            </label>
                            <label className='radio radio-primary'>
                                <input
                                    type='radio'
                                    name='nationality'
                                    checked={props.info.id_type === '2' || nationality === '2'}
                                    onChange={() => setNationality('2')}
                                />
                                <span />
                                ต่างชาติ
                            </label>
                        </div>
                    </div> */}
                    <div className='col-12 col-md-6 form-group'>
                        <label>หมายเลขประจำตัว</label>
                        <input
                            type='text'
                            // inputMode={props.info.id_type === '1' || nationality === '1' ? 'tel' : 'text'}
                            inputMode='tel'
                            className='form-control rounded-lg'
                            name='personalid'
                            defaultValue={props.info.personal_id}
                            value={personalid}
                            onChange={(e) => setPersonalid(e.target.value)}
                            maxLength='13'
                            onInput={maxLengthCheck}
                        />
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <label>เบอร์ติดต่อ</label>
                        <input
                            type='text'
                            inputMode='tel'
                            className='form-control rounded-lg'
                            name='contactnumber'
                            defaultValue={props.info.mobile}
                            value={contactnumber}
                            onChange={(e) => setContactnumber(e.target.value)}
                            maxLength='10'
                            onInput={maxLengthCheck}
                        />
                    </div>
                    {/* <div className='col-12 col-md-6 form-group'>
                        <label>วัน/เดือน/ปีเกิด</label>
                        <InputMask
                            mask='99/99/9999'
                            alwaysShowMask={true}
                            name='birthdate'
                            inputMode='tel'
                            className='form-control rounded-lg'
                            defaultValue={
                                props.info.birthdate !== '' ? (
                                    Moment(new Date(props.info.birthdate)).format('DD/MM/YYYY')
                                ) : (
                                    ''
                                )
                            }
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                        />
                    </div> */}
                    <div className='col-12 text-right'>
                        <button type='button' className='btn btn-primary rounded-lg' onClick={btnUpdate}>
                            บันทึกการเปลี่ยนแปลง
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
