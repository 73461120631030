import React from 'react';

// import NumberFormat from 'react-number-format';

import Moment from 'moment';

import 'moment/locale/th';

// import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function CustomersInformation(props) {
    return (
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <h2 className='d-inline-block mb-5'>รายละเอียดสมาชิก</h2>
                <div className='row justify-content-between m-0'>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> ชื่อ-นามสกุล : {props.info.name}</h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        {/* <h6 className='d-block m-0'> นามสกุล : {props.info.last_name} </h6> */}
                        <h6 className='d-block m-0'> รหัสสมาชิก : {props.info.user_code} </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> เลขบัตรประจำตัวประชาชน : {props.info.personal_id} </h6>
                        {/* <h6 className='d-block m-0'>
                            {' '}
                            ประเภทข้อมูลประจำตัว :{' '}
                            {props.info.id_type === '' ? '' : props.info.id_type === 'C' ? 'ไทย' : 'ต่างชาติ'}{' '}
                        </h6> */}
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> เบอร์ติดต่อ : {props.info.mobile} </h6>
                        {/* <h6 className='d-block m-0'>
                            {' '}
                            หมายเลข{props.info.id_type === '' ? (
                                ''
                            ) : props.info.id_type === 'C' ? (
                                'บัตรประชาชน'
                            ) : (
                                'พาสปอร์ต'
                            )}{' '}
                            : {props.info.personal_id}{' '}
                        </h6> */}
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        {/* <h6 className='d-block m-0'> เบอร์ติดต่อ : {props.info.mobile} </h6> */}
                        <h6 className='d-block m-0'> หมายเลขกระเป๋า : {props.info.wallet_address} </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> e-mail : {props.info.email} </h6>
                        {/* <h6 className='d-block m-0'>
                            {' '}
                            วัน/เดือน/ปีเกิด :{' '}
                            {props.info.birthdate !== '' ? (
                                Moment(new Date(props.info.birthdate))
                                    .add(543, 'year')
                                    .locale('th')
                                    .format('DD/MMMM/YYYY')
                            ) : (
                                ''
                            )}{' '}
                        </h6> */}
                    </div>
                    {/* <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> ที่อยู่ : {address} </h6>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
