import React, { useState } from 'react';

import Swal from 'sweetalert2/dist/sweetalert2.js';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function CustomersSecurity(props) {
    const [ password, setPassword ] = useState('chailai');
    const [ confirmpassword, setConfirmPassword ] = useState('chailai');

    const btnSubmit = () => {
        if (password === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรหัสผ่านใหม่ให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        if (confirmpassword === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณายืนยันรหัสผ่านใหม่ให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        } else {
            if (password !== confirmpassword) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณายืนยันรหัสผ่านใหม่ให้ถูกต้อง',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
        }
        // return;
        // console.log(props.username)
        // console.log(password)
        props.btnChangePassword(props.username, password);
    };

    return (
        <div className='setting-change-password-page'>
            <div className='row justify-content-center align-content-center'>
                <div className='col-lg-8 col-12 p-md-20 pt-10 bg-white'>
                    <div className='row justify-content-center'>
                        <div className='col-12 text-center p-0'>
                            <h6 className='text-dark font-weight-bold mb-10'>ตั้งค่ารหัสผ่าน</h6>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-3 text-lg-right text-left'>รหัสผ่านใหม่</label>
                        <div className='col-9'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='col-form-label col-3 text-lg-right text-left'>ยืนยันรหัสผ่านใหม่</label>
                        <div className='col-9'>
                            <input
                                type='text'
                                className='form-control form-control-lg rounded-lg'
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <button type='button' className='btn btn-primary rounded-lg' onClick={btnSubmit}>
                            บันทึกการเปลี่ยนแปลง
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
