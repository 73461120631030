import React, { useCallback, useState } from 'react';

// import { Modal } from 'react-bootstrap';

import { useSelector } from 'react-redux';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import Moment from 'moment';

import 'moment/locale/th';

import {Table,Input} from 'antd' ;

// import { useDropzone } from 'react-dropzone';

import Select, { components } from 'react-select';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import config from '../../../../config';
import { AndroidTwoTone } from '@material-ui/icons';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const controlStyles = {
    borderRadius: '0.85rem',
    // padding: "5px",
    // background: "#F3F6F9",
    // color: "#3F4254",
    border: '1px solid #E4E6EF'
    // borderStyle: "solid"
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
};

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2022,
    month: 1,
    day: 1
};

const outputList = [
    {
        label: 'ทั่วไป',
        value: 'base64_excel'
    },
    {
        label: 'text',
        value: 'base64_text'
    }
];

export function ReportSaleVatMain(props) {
    const [ loading, setLoading ] = useState(false);
    const [ cdate, setCDate ] = useState({
        from: null,
        to: null
    });
    const [ idcard, setIDCard] = useState('');
    const [ username, setUsername] = useState('');
    const [ output, setOutput ] = useState({
        label: 'ทั่วไป',
        value: 'base64_excel'
    });

    const selectOutput = (item) => {
        if (item) {
            setOutput(item);
        } else {
            setOutput({
                label: 'ทั่วไป',
                value: 'base64_excel'
            });
        }
    };

    const emptyODate = () => {
        setCDate({
            from: null,
            to: null
        });
    };

    const CdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    cdate.from && cdate.to ? (
                        cdate.from.year +
                        '/' +
                        ('0' + cdate.from.month).slice(-2) +
                        '/' +
                        ('0' + cdate.from.day).slice(-2) +
                        ' - ' +
                        cdate.to.year +
                        '/' +
                        ('0' + cdate.to.month).slice(-2) +
                        '/' +
                        ('0' + cdate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${cdate.from && cdate.to ? ' rounded-right-0' : ''}`}
            />
            {cdate.from &&
            cdate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyODate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    const btnSearch = () => {
        let param = {
            output_type: output.value
        };
        if (cdate.from !== null && cdate.to !== null) {
            param['start'] = cdate.from.year + '-' + ('0' + cdate.from.month).slice(-2) + '-' + ('0' + cdate.from.day).slice(-2)
            param['end'] = cdate.to.year + '-' + ('0' + cdate.to.month).slice(-2) + '-' + ('0' + cdate.to.day).slice(-2)
        }
        if (idcard !== '') {
            param['idcard'] = idcard;
        }
        if (username !== '') {
            param['username'] = username;
        }
        setLoading(true);
        fetch(config.chailai_url + 'admin/reports/sale-vat', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.admin_secrect,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.setAttribute('type', 'hidden');
                    link.href = 'data:text/plain;base64,' + result.base64;
                    let filename = 'SaleVatReport';
                    if (output.value === 'base64_excel') {
                        link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.xlsx`;
                    } else {
                        link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.txt`;
                    }
                    link.click();
                    document.body.removeChild(link);
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-body'>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>เลขบัตรประจำตัวประชาชน</label>
                                            <input
                                                type='text'
                                                className='form-control rounded-lg'
                                                value={idcard}
                                                onChange={(e) => setIDCard(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>ชื่อผู้ใช้</label>
                                            <input
                                                type='text'
                                                className='form-control rounded-lg'
                                                value={username}
                                                onChange={(u) => setUsername(u.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่ชำระเงิน</label>
                                            <DatePicker
                                                value={cdate}
                                                onChange={setCDate}
                                                renderInput={CdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='cdate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>ประเภทไฟล์</label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={output}
                                                options={outputList}
                                                placeholder='เลือก'
                                                id='dealerposition'
                                                name='position'
                                                onChange={selectOutput}
                                                isClearable={false}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                        <button
                                            type='button'
                                            onClick={btnSearch}
                                            className='btn btn-sm btn-primary rounded-lg'
                                        >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}