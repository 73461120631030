import React, { useCallback, useState } from 'react';

// import { Modal } from 'react-bootstrap';

import { useSelector } from 'react-redux';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import Moment from 'moment';

import 'moment/locale/th';

import {Table,Input} from 'antd' ;

// import { useDropzone } from 'react-dropzone';

import Select, { components } from 'react-select';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import config from '../../../../config';
import { AndroidTwoTone } from '@material-ui/icons';


// const dataSource = [
//     {
//       key: '1',
//       name: 'Mike',
//       age: 32,
//       address: '10 Downing Street',
//     },
//     {
//       key: '2',
//       name: 'John',
//       age: 42,
//       address: '10 Downing Street',
//     },
//   ];
  
//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//     },
//     {
//       title: 'Age',
//       dataIndex: 'age',
//       key: 'age',
//     },
//     {
//       title: 'Address',
//       dataIndex: 'address',
//       key: 'address',
//     },
//   ];

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

// const formatBytes = (bytes, decimals = 2) => {
//     if (bytes === 0) return '0 Bytes';
//     const k = 1024;
//     const dm = decimals < 0 ? 0 : decimals;
//     const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
//     const i = Math.floor(Math.log(bytes) / Math.log(k));
//     return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
// };

const controlStyles = {
    borderRadius: '0.85rem',
    // padding: "5px",
    // background: "#F3F6F9",
    // color: "#3F4254",
    border: '1px solid #E4E6EF'
    // borderStyle: "solid"
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
};

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2022,
    month: 1,
    day: 1
};

// set('pending','paid','cancel','expired','shipping','completed')
const statusList = [
    {
        label: 'รอการชำระเงิน',
        value: 'pending'
    },
    {
        label: 'ชำระเงินแล้ว',
        value: 'paid'
    },
    {
        label: 'ยกเลิก',
        value: 'cancel'
    },
    {
        label: 'หมดอายุ',
        value: 'expired'
    },
    {
        label: 'จัดส่งแล้ว',
        value: 'shipping'
    },
    {
        label: 'ยืนยันรายการ',
        value: 'completed'
    }
];

const outputList = [
    {
        label: 'ทั่วไป',
        value: 'excel_base64'
    }
    // {
    //     label: 'Kerry',
    //     value: 'excel_kerry'
    // }
];

export function ReportVoucherMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ oid, setOid ] = useState('');
    const [ odate, setODate ] = useState({
        from: null,
        to: null
    });
    const [ pdate, setPDate ] = useState({
        from: null,
        to: null
    });
    const [ status, setStatus ] = useState('');
    const [ output, setOutput ] = useState({
        label: 'ทั่วไป',
        value: 'excel_base64'
    });
    // const [ importfile, setImport ] = useState('');

    // const [ showModal, setShow ] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const selectStatus = (item) => {
        if (item) {
            setStatus(item);
        } else {
            setStatus('');
        }
    };

    const selectOutput = (item) => {
        if (item) {
            setOutput(item);
        } else {
            setOutput({
                label: 'ทั่วไป',
                value: 'excel_base64'
            });
        }
    };

    const emptyODate = () => {
        setODate({
            from: null,
            to: null
        });
    };

    const emptyPDate = () => {
        setPDate({
            from: null,
            to: null
        });
    };

    const OdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    odate.from && odate.to ? (
                        odate.from.year +
                        '/' +
                        ('0' + odate.from.month).slice(-2) +
                        '/' +
                        ('0' + odate.from.day).slice(-2) +
                        ' - ' +
                        odate.to.year +
                        '/' +
                        ('0' + odate.to.month).slice(-2) +
                        '/' +
                        ('0' + odate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${odate.from && odate.to ? ' rounded-right-0' : ''}`}
            />
            {odate.from &&
            odate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyODate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    const PdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    pdate.from && pdate.to ? (
                        pdate.from.year +
                        '/' +
                        ('0' + pdate.from.month).slice(-2) +
                        '/' +
                        ('0' + pdate.from.day).slice(-2) +
                        ' - ' +
                        pdate.to.year +
                        '/' +
                        ('0' + pdate.to.month).slice(-2) +
                        '/' +
                        ('0' + pdate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${pdate.from && pdate.to ? ' rounded-right-0' : ''}`}
            />
            {pdate.from &&
            pdate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyPDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    // const importFile = () => {
    //     if (importfile === '') {
    //         SwalBs.fire({
    //             html: 'กรุณาเลือกไฟล์ที่ต้องการอัพโหลด',
    //             icon: 'warning',
    //             confirmButtonText: 'รับทราบ',
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false
    //         });
    //         return;
    //     }
    //     setLoading(true);
    //     fetch(config.crypto_url + 'admin/reports/shipping/import', {
    //         method: 'post',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json'
    //             // secret: config.secret,
    //         },
    //         body: JSON.stringify({
    //             token: user.token,
    //             base64: importfile
    //         })
    //     })
    //         .then((response) => response.json())
    //         .then((result) => {
    //             setLoading(false);
    //             if (result.responsecode === 0) {
    //                 SwalBs.fire({
    //                     html: 'ดำเนินการเรียบร้อย',
    //                     icon: 'success',
    //                     confirmButtonText: 'รับทราบ',
    //                     allowOutsideClick: false,
    //                     allowEscapeKey: false,
    //                     allowEnterKey: false
    //                 }).then((result) => {
    //                     if (result.isConfirmed) {
    //                         handleClose();
    //                     }
    //                 });
    //             } else {
    //                 SwalBs.fire({
    //                     html: 'ไม่สามารถดำเนินการได้ กรุณาลองใหม่อีกครั้ง',
    //                     icon: 'warning',
    //                     confirmButtonText: 'รับทราบ',
    //                     allowOutsideClick: false,
    //                     allowEscapeKey: false,
    //                     allowEnterKey: false
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             SwalBs.fire({
    //                 html: 'ไม่สามารถดำเนินการได้ กรุณาติดต่อเจ้าหน้าที่',
    //                 icon: 'error',
    //                 confirmButtonText: 'รับทราบ',
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false
    //             });
    //         });
    // };

    const btnSearch = () => {
        let param = {
            token: user.token,
            output: output.value
        };
        let filters = {};
        if (oid !== '') {
            filters['id'] = oid;
        }
        if (odate.from !== null && odate.to !== null) {
            filters['order_date'] = {
                start:
                    odate.from.year + '-' + ('0' + odate.from.month).slice(-2) + '-' + ('0' + odate.from.day).slice(-2),
                end: odate.to.year + '-' + ('0' + odate.to.month).slice(-2) + '-' + ('0' + odate.to.day).slice(-2)
            };
        }
        // else {
        //     filters['order_date'] = {
        //         start: Moment(new Date()).format('YYYY-MM-DD'),
        //         end: Moment(new Date()).format('YYYY-MM-DD')
        //     };
        // }
        if (pdate.from !== null && pdate.to !== null) {
            filters['paid_date'] = {
                start:
                    pdate.from.year + '-' + ('0' + pdate.from.month).slice(-2) + '-' + ('0' + pdate.from.day).slice(-2),
                end: pdate.to.year + '-' + ('0' + pdate.to.month).slice(-2) + '-' + ('0' + pdate.to.day).slice(-2)
            };
        }
        // else {
        //     filters['paid_date'] = {
        //         start: Moment(new Date()).format('YYYY-MM-DD'),
        //         end: Moment(new Date()).format('YYYY-MM-DD')
        //     };
        // }
        if (status !== '') {
            filters['status'] = status.value;
        }
        if (Object.keys(filters).length > 0) {
            param['filters'] = filters;
        }
        setLoading(true);
        fetch(config.crypto_url + 'admin/reports/voucher', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.setAttribute('type', 'hidden');
                    link.href = 'data:text/plain;base64,' + result.base64;
                    // let filename = '';
                    // if (output.value === 'excel_base64') {
                    //     filename = 'VoucherReport'; // ExcelTemplate
                    // } else {
                    //     filename = 'KerryExpressImportTemplate';
                    // }
                    let filename = 'VoucherReport'
                    link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.xlsx`;
                    link.click();
                    document.body.removeChild(link);
                } else {
                    SwalBs.fire({
                        html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    // const onDrop = useCallback((acceptedFiles) => {
    //     acceptedFiles.forEach((file) => {
    //         const reader = new FileReader();
    //         reader.onabort = () => console.log('file reading was aborted');
    //         reader.onerror = () => console.log('file reading has failed');
    //         reader.onload = () => {
    //             const binaryStr = reader.result.split(',').pop();
    //             setImport(binaryStr);
    //         };
    //         reader.readAsDataURL(file);
    //     });
    // }, []);

    // const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    //     onDrop,
    //     accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // });

    // const files = acceptedFiles.map((file, index) => {
    //     return (
    //         <div className='dz-preview dz-file-preview dz-processing dz-error dz-complete' key={index}>
    //             <div className='dz-image'>
    //                 {/* {file.path.includes('.xlsx') ? (
    //                     <i className='far fa-file-excel'/>
    //                 ) : (
    //                     <i className='far fa-file-alt' />
    //                 )} */}
    //                 {/* <img data-dz-thumbnail alt="thumbnail"/> */}
    //             </div>
    //             <div className='dz-details'>
    //                 <div className='dz-size'>
    //                     <span data-dz-size>
    //                         <strong>{formatBytes(file.size).split(' ')[0]}</strong>{' '}
    //                         {formatBytes(file.size).split(' ')[1]}
    //                     </span>
    //                 </div>
    //                 <div className='dz-filename'>
    //                     <span data-dz-name>{file.path}</span>
    //                 </div>
    //             </div>
    //             {/* <div className="dz-progress">
    //                 <span className="dz-upload" data-dz-uploadprogress />
    //             </div>
    //             <div className="dz-error-message">
    //                 <span data-dz-errormessage>Server responded with 0 code.</span>
    //             </div>
    //             <div className="dz-success-mark">
    //                 <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    //                     <title>Check</title>
    //                     <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    //                         <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" strokeOpacity="0.198794158" stroke="#747474" fillOpacity="0.816519475" fill="#FFFFFF" />
    //                     </g>
    //                 </svg>
    //             </div>
    //             <div className="dz-error-mark">
    //                 <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    //                     <title>Error</title>
    //                     <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
    //                         <g stroke="#747474" strokeOpacity="0.198794158" fill="#FFFFFF" fillOpacity="0.816519475">
    //                             <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" />
    //                         </g>
    //                     </g>
    //                 </svg>
    //             </div>
    //             <a className="dz-remove" href="javascript:undefined;" data-dz-remove>
    //                 Remove file
    //             </a> */}
    //         </div>
    //     );
    // });

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    {/* <div className='col-12 row m-0 mb-5 justify-content-end'>
                        <button type='button' className='btn btn-primary rounded-lg' onClick={handleShow}>
                            <i className='fas fa-check' /> นำเข้า Excel
                        </button>
                    </div> */}
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-body'>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>หมายเลขรายการสั่งซื้อ</label>
                                            <input
                                                type='text'
                                                className='form-control rounded-lg'
                                                value={oid}
                                                onChange={(e) => setOid(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่สั่งซื้อ</label>
                                            <DatePicker
                                                value={odate}
                                                onChange={setODate}
                                                renderInput={OdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='odate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่ชำระเงิน</label>
                                            <DatePicker
                                                value={pdate}
                                                onChange={setPDate}
                                                renderInput={PdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='pdate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>สถานะรายการ</label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={status}
                                                options={statusList}
                                                placeholder='เลือก'
                                                id='dealerposition'
                                                name='position'
                                                onChange={selectStatus}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>ประเภทไฟล์</label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={output}
                                                options={outputList}
                                                placeholder='เลือก'
                                                id='dealerposition'
                                                name='position'
                                                onChange={selectOutput}
                                                isClearable={false}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                        <button
                                            type='button'
                                            onClick={btnSearch}
                                            className='btn btn-sm btn-primary rounded-lg'
                                        >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <Modal show={showModal} onHide={handleClose} size='xl' centered aria-labelledby='import-excel-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='import-excel-modal' className='col-11 p-0'>
                        นำเข้าข้อมูล
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header> */}
                {/* <Modal.Body> */}
                    {/* <div className='row d-flex justify-content-center'> */}
                        {/* <div className="col-sm-12 d-flex justify-content-between py-5">
                            <span className='my-auto mr-15 font-weight-bolder font-size-lg'>ดาวน์โหลดตัวอย่างไฟล์ : </span>
                            <span className='text-right'>
                                <button type="button" className="btn btn-primary rounded-lg" onClick={() => downloadExcelFile(prop.response.base64)}>
                                    <i className="fas fa-file-excel"></i> ดาวน์โหลด Excel
                                </button>
                            </span>
                        </div> */}
                        {/* <div className='col-sm-12'>
                            <div
                                {...getRootProps({
                                    className: 'dropzone dropzone-default dz-clickable cus-dropzone bg-white'
                                })}
                            >
                                <input {...getInputProps()} />
                                {acceptedFiles.length === 0 ? (
                                    <>
                                        <div className='uppy-DragDrop-inner'>
                                            <svg
                                                aria-hidden='true'
                                                focusable='false'
                                                className='uppy-c-icon uppy-DragDrop-arrow'
                                                width={16}
                                                height={16}
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M11 10V0H5v10H2l6 6 6-6h-3zm0 0' fillRule='evenodd' />
                                            </svg>
                                            <div className='uppy-DragDrop-label'>
                                                วางไฟล์ที่นี่หรือ<span className='uppy-DragDrop-browse'>เรียกดู</span>
                                            </div>
                                            <span className='uppy-DragDrop-note' />
                                        </div>
                                    </>
                                ) : (
                                    <>{files}</>
                                )}
                            </div>
                        </div>
                        <div className='col-sm-12 d-flex justify-content-end pt-5'>
                            <button type='button' className='btn btn-success rounded-lg' onClick={importFile}>
                                <i className='fas fa-file-upload' /> อัพโหลด
                            </button>
                        </div>
                    </div> */}
                {/* </Modal.Body> */}
            {/* </Modal> */}


            {/* <Table dataSource={dataSource} columns={columns} /> 
            <table>
                <tr><td></td></tr>
            </table> */}
        </div>
    );
}