import React, { useEffect } from 'react';

import $ from 'jquery';

export function ProductTable(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.productlist).length > 0) {
                    if (props.productlist.responsecode === 0) {
                        $('#product-list-table').DataTable({
                            data: props.productlist.products,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            order: false,
                            columns: [
                                {
                                    title: 'ดูรายละเอียด',
                                    className: 'dt-body-center',
                                    data: null, // 'btn btn-icon btn-primary view-shop-info'
                                    render: function(data, type, row) {
                                        // let classname = '';
                                        // if (data.status === 'disable') {
                                        //     classname = 'btn btn-icon btn-primary view-shop-info'
                                        // } else {
                                        //     classname = 'btn btn-icon btn-primary disabled view-shop-info'
                                        // }
                                        return `<button
                                                    type='button'
                                                    class='btn btn-icon btn-primary view-shop-info'
                                                    data-id=${data.id}
                                                    >
                                                        <i class='fa fa-search'></i>
                                                </button>`;
                                    }
                                },
                                // {
                                //     title: 'รูปภาพสินค้า',
                                //     className: 'dt-body-center',
                                //     data: null,
                                //     render: function(data, type, row) {
                                //         return `<img />`;
                                //     }
                                // },
                                {
                                    title: 'ชื่อสินค้า',
                                    data: function(value) {
                                        if (value.name !== null && value.name !== '') {
                                            return value.name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                // {
                                //     title: 'รายละเอียดร้านค้า',
                                //     render : function (data, type, row) {
                                //     // data: function(value) {
                                //         // if (value.gallery.cover_image !== null && value.gallery.cover_image !== '') {
                                //             // return `data:image/jpeg;base64,${value.gallery.cover_image}`;
                                //         // return `<img src={data:image/jpeg;base64,${data.gallery.cover_image}}`;
                                //         return console.log(row)
                                //         // } else {
                                //         //     return '-';
                                //         // }
                                //     }
                                // },
                                {
                                    title: 'ประเภทสินค้า',
                                    data: function(value) {
                                        if (value.category_title !== null && value.category_title !== '') {
                                            return value.category_title;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ชื่อร้านค้า',
                                    data: function(value) {
                                        if (value.shop_name !== null && value.shop_name !== '') {
                                            return value.shop_name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.productlist ]
    );

    useEffect(() => {
        $(document).ready(function() {
            $('.view-shop-info').click(function() {
                props.btnView($(this).attr('data-id'));
            });
        });
    });

    return (
            <div className='col-12 my-5'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        {Object.keys(props.productlist).length <= 0 ? (
                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                <div className='col-12 text-center'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {props.productlist.responsecode !== 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>
                                                ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                กรุณาลองใหม่อีกครั้ง
                                            </h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-12 p-0'>
                                            <table
                                                id='product-list-table'
                                                className='table table-striped table-bordered'
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
    );
}