const config = {
    // crypto_url: 'http://localhost:5005/',
    crypto_url: 'http://192.168.159.71:5003/',
    chailai_url: 'http://192.168.159.71:5004/',
    crypto_url: 'https://chailai-shop.simk4.com/',
    chailai_url: 'https://chailai-api-v2.simk4.com/',
    version: 'v1/',
    secret: '***',
    admin_secrect: 'D9F7C1DA8F53FE655982C8B8C554E',
    maintain: false
};

export default config;
