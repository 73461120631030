import React from 'react';

export function ProductDetail(props) {
    const imgRef = React.createRef()
    const pv = parseFloat(props.productinfo.product.pv)
    const cb = parseFloat(props.productinfo.product.cb)
    const share = (props.productinfo.product.share != '' ? parseFloat(props.productinfo.product.share) : 0)
    const income = parseFloat(props.productinfo.product.price) - ((pv + cb + share))

    const btnBack = () => {
        props.setPage('main');
        props.setProductinfo({})
    };

    const btnApproved = () => {
        props.btnApproved(props.productinfo.product.id.toString());
    };

    return (
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <div className='p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card rounded-lg'>
                    <div className='card-body rounded-lg rounded-top-0'>
                        <h3 className='d-inline-block mb-5'>รายละเอียดสินค้า</h3>
                        <div className='row justify-content-between m-0'>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รูปภาพสินค้า : </h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <div className='row'>
                                    <img className='col-6' ref={imgRef} src={`data:image/jpeg;base64,${props.productinfo.product.gallery.cover_image}`}/>
                                </div>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> ชื่อสินค้า : {props.productinfo.product.name}</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รายละเอียดสินค้า :</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <pre className='d-block m-0 100px'> {props.productinfo.product.description}</pre>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ประเภทสินค้า : {props.productinfo.product.category_title}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ชื่อร้านค้า : {props.productinfo.product.shop_name}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ราคา/ชิ้น : {parseFloat(props.productinfo.product.price)} บาท</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ภาษีมูลค่าเพิ่ม : {parseFloat(props.productinfo.product.vat)} บาท</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> cb : {cb}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> yg : {pv}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> share : {share} บาท</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 style={income > 0 ? {color: 'black'} : {color: 'red'}}
                                    className='d-block m-0'> สุทธิ/ชิ้น : {income} บาท</h6>
                            </div>
                        </div>
                        <h3 className='d-inline-block mb-5'>รายละเอียดการจัดส่ง</h3>
                        <div className='row justify-content-between m-0'>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ความสูง : {props.productinfo.product.height} cm</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ความกว้าง : {props.productinfo.product.width} cm</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ความยาว : {props.productinfo.product.lenght} cm</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> น้ำหนัก : {props.productinfo.product.weight} kg</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ประเภทการคิดค่าขนส่ง : {props.productinfo.product.shipping_method ? props.productinfo.product.shipping_method.shipping_method : ''}</h6>
                                {/* {props.productinfo.product.shipping_method ? props.productinfo.product.shipping_method.shipping_method==='kerry' ? <h6>[{props.productinfo.product.shipping_method.package_type}]</h6> : '' : ''} */}
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ราคาขนส่ง : {props.productinfo.product.shipping_price != '' ? parseFloat(props.productinfo.product.shipping_price) : 0} บาท</h6>
                            </div>
                        </div>
                        {props.productinfo.product.status === 'disable' ? (
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button type='button' className='btn btn-success rounded-lg' onClick={btnApproved}>
                                    <i className='far fa-check-circle' /> อนุมัติ
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}