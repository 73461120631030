import React from 'react';

export function ShopDetail(props) {
    const btnBack = () => {
        props.setPage('main');
        props.setShopinfo({});
    };

    const btnApproved = () => {
        props.btnApproved(props.shopinfo.info.id.toString());
    };

    const btnRejected = () => {
        props.btnRejected(props.shopinfo.info.id.toString());
    };

    const btnPublish = () => {
        props.btnPublish(props.shopinfo.info.id.toString());
    };

    return (
        <div className='row m-0'>
            <div className='col-12 p-0'>
                <div className='p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card rounded-lg'>
                    <div className='card-body rounded-lg rounded-top-0'>
                        <h3 className='d-inline-block mb-5'>รายละเอียดร้านค้า</h3>
                        <div className='row justify-content-between m-0'>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รูปภาพร้านค้า : </h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <div className='row'>
                                    <img className='col-12' src={`data:image/jpeg;base64,${props.shopinfo.info.banner}`} />
                                </div>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> ชื่อร้านค้า : {props.shopinfo.info.name}</h6>
                            </div>
                            {/* <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รายละเอียดร้านค้า : {props.shopinfo.info.description}</h6>
                            </div> */}
                            <div className='col-12 col-md-12 form-group'>
                                <h6 className='d-block m-0'> รายละเอียดร้านค้า :</h6>
                            </div>
                            <div className='col-12 col-md-12 form-group'>
                                <pre className='d-block m-0'> {props.shopinfo.info.description}</pre>
                            </div>
                            <div className='col-12 form-group'>
                                <h6 className='d-block m-0'> ที่อยู่ร้านค้า : {props.shopinfo.info.address1} {props.shopinfo.info.subdistrict} 
                                {props.shopinfo.info.district} {props.shopinfo.info.province} {props.shopinfo.info.postalcode}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> เลขประจำตัวผู้เสียภาษี : {props.shopinfo.info.taxpayer}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ชื่อ - นามสกุลเจ้าของร้าน : {props.shopinfo.info.user_first_name} {props.shopinfo.info.user_last_name}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ชื่อผู้ใช้งาน : {props.shopinfo.info.user_username}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> รหัสสมาชิก : {props.shopinfo.info.user_id}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> เลขบัตรประจำตัวประชาชน : {props.shopinfo.info.user_personal_id}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> เบอร์ติดต่อ : {props.shopinfo.info.user_contact_number}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> Email : {props.shopinfo.info.email}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> ลงทะเบียนวันที่ : {props.shopinfo.info.created_date}</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> รูปบัตรประจำตัวประชาชน</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <h6 className='d-block m-0'> รูปหน้าสมุดบัญชีธนาคาร</h6>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                                <div className='row'>
                                    <img className='col-10' src={`data:image/jpeg;base64,${props.shopinfo.info.id_card}`} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6 form-group'>
                            <div className='row'>
                                    <img className='col-10' src={`data:image/jpeg;base64,${props.shopinfo.info.book_bank}`} />
                                </div>
                            </div>
                        </div>
                        {props.shopinfo.info.status === 'pending' ? (
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button type='button' className='btn btn-success rounded-lg' onClick={btnApproved}>
                                    <i className='far fa-check-circle' /> อนุมัติ
                                </button>
                                <button type='button' className='btn btn-danger rounded-lg' onClick={btnRejected}>
                                    <i className='fas fa-ban' /> ปฏิเสธ
                                </button>
                            </div>
                        ) : ''}
                        {props.shopinfo.info.status === 'approved' ? (
                            <div className='col-sm-12 d-flex justify-content-end pt-5'>
                                <button
                                    type='button'
                                    className={props.shopinfo.info.publish === 'publish' ? 'btn btn-danger rounded-lg' : 'btn btn-success rounded-lg'}
                                    onClick={btnPublish}>
                                        <i className='far fa-check-circle' />
                                            {props.shopinfo.info.publish === 'publish' ? 'ปิดร้านค้า' : 'เปิดร้านค้า'}
                                </button>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}