import React, { useEffect } from 'react';

import $ from 'jquery';

export function SaleCommissionTable(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.salelist).length > 0) {
                    if (props.salelist.responsecode === 0) {
                        $('#sales-list-table').DataTable({
                            data: props.salelist.info,
                            lengthChange: false,
                            pageLength: -1,
                            dom: 'Bfrtip',
                            buttons: [ 'excel' ],
                            responsive: true,
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            order: [2, 'desc'],
                            columns: [
                                {
                                    className: 'dt-body-center',
                                    defaultContent: '',
                                    orderable: false,
                                    data: null,
                                    render: function(data, type, row) {
                                        return `<button
                                                    type='button'
                                                    class='btn btn-icon btn-primary view-sale-info'
                                                    data-id=${data.commission_id}
                                                    >
                                                        <i class='fa fa-search'></i>
                                                </button>`;
                                    }
                                },
                                // {
                                //     className: 'bt-control',
                                //     orderable: false,
                                //     data: null,
                                //     defaultContent: '',
                                // },
                                {
                                    title: 'ชื่อร้าน',
                                    data: function(value) {
                                        if (value.shop_name !== null && value.shop_name !== '') {
                                            return value.shop_name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่คำนวณ',
                                    data: function(value) {
                                        if (value.calc_date !== null && value.calc_date !== '') {
                                            return value.calc_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'รายได้',
                                    data: function(value) {
                                        if (value.total !== null && value.total !== '') {
                                            return value.total;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.salelist ]
    );

    useEffect(() => {
        $(document).ready(function() {
            $('.view-sale-info').click(function() {
                props.btnView($(this).attr('data-id'));
            });
        });
    });

    return (
            <div className='col-12 my-5'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        {Object.keys(props.salelist).length <= 0 ? (
                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                <div className='col-12 text-center'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {props.salelist.responsecode !== 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>
                                                ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                กรุณาลองใหม่อีกครั้ง
                                            </h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        <div className='col-12 p-0'>
                                            <table
                                                id='sales-list-table'
                                                className='table table-striped table-bordered'
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
    );
}