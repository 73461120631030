import React, { useState,useEffect } from 'react';

import { useSelector } from 'react-redux';

import config from '../../../../config';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import { SaleCommissionTable }  from './components';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import Moment from 'moment';

import { Modal } from 'react-bootstrap';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

const minimumDate = {
    year: 2022,
    month: 1,
    day: 1
};

export function CommissionSalesMain(props) {
    const { user } = useSelector((state) => state.auth);
    const [ salelist,setSalelist] = useState([])
    const [ loading,setLoading ] = useState(false)
    const [ showModal, setShow ] = useState(false);
    const [ saleinfo,setSaleInfo] = useState({})
    const [ comdate, setComDate ] = useState({
            from: null,
            to: null
        });

    const handleClose = () => setShow(false);

    const emptyComDate = () => {
            setComDate({
                from: null,
                to: null
            });
        };

    const ComdateInput = ({ ref }) => (
        <div className='input-group'>
            <div className='input-group-prepend'>
                <span className='input-group-text rounded-lg rounded-right-0'>
                    <span className='fas fa-calendar-alt' />
                </span>
            </div>
            <input
                type='text'
                readOnly
                ref={ref}
                value={
                    comdate.from && comdate.to ? (
                        comdate.from.year +
                        '/' +
                        ('0' + comdate.from.month).slice(-2) +
                        '/' +
                        ('0' + comdate.from.day).slice(-2) +
                        ' - ' +
                        comdate.to.year +
                        '/' +
                        ('0' + comdate.to.month).slice(-2) +
                        '/' +
                        ('0' + comdate.to.day).slice(-2)
                    ) : (
                        ''
                    )
                }
                className={`form-control rounded-lg rounded-left-0${comdate.from && comdate.to ? ' rounded-right-0' : ''}`}
            />
            {comdate.from &&
            comdate.to && (
                <div className='input-group-append cursor-pointer' onClick={emptyComDate}>
                    <span className='input-group-text rounded-lg rounded-left-0 bg-danger'>
                        <span className='fas fa-times' />
                    </span>
                </div>
            )}
        </div>
    );

    useEffect(() => {
        let param = {
            token: user.token,
            com_date: {'start': date(), 'end': date()}
        };
        getCommissionSalesList(param);
    }, []);

    const date = () => {
        let date = new Date();
        date.setDate(date.getDate() -1) // yesterday
        date = Moment(date).format('YYYY-MM-DD')
        return date
    }

    async function getCommissionSalesList(param, output='') {
        setLoading(true);
        setSalelist([])
        return fetch(config.crypto_url + 'admin/commission/get-commission', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                if (result) {
                    setLoading(false);
                    if (output === 'export') {
                        return result
                    } else {
                        setSalelist(result)
                    }
                };
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (comid) => {
        setShow(true);
        setLoading(true);
        setSaleInfo({})
        fetch(config.crypto_url + 'admin/commission/get-commission-detail', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: user.token,
                com_id: comid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setSaleInfo(result.info);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnSearch = () => {
        let param = {
            token: user.token,
            com_date: {}
        };
        if (comdate.from !== null && comdate.to !== null) {
            param['com_date'] = {
                'start': comdate.from.year + '-' + ('0' + comdate.from.month).slice(-2) + '-' + ('0' + comdate.from.day).slice(-2),
                'end': comdate.to.year + '-' + ('0' + comdate.to.month).slice(-2) + '-' + ('0' + comdate.to.day).slice(-2)
            }}
        getCommissionSalesList(param)
    };

    async function btnExport() {
        let param = {
            token: user.token,
            output: 'excel_base64'
        };
        if (comdate.from !== null && comdate.to !== null) {
            param['com_date'] = {
                'start': comdate.from.year + '-' + ('0' + comdate.from.month).slice(-2) + '-' + ('0' + comdate.from.day).slice(-2),
                'end': comdate.to.year + '-' + ('0' + comdate.to.month).slice(-2) + '-' + ('0' + comdate.to.day).slice(-2)
            }
        } else {
            param['com_date'] = {'start':date(), 'end':date()}
        }
        const result = await getCommissionSalesList(param, 'export')
        if (result) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.setAttribute('type', 'hidden');
            link.href = 'data:text/plain;base64,' + result.base64;
            let filename = 'commissionSaleReport';
            link.download = `${filename}${Moment(new Date()).format('DDMMYYYY')}.xlsx`;
            link.click();
            document.body.removeChild(link)
        }
    }

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row main-page'>
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-body'>
                                <div className='row form-group'>
                                    <div className='col-12 col-md-3 form-group'>
                                        <div className='form-group mb-0'>
                                            <label>วันที่รายการคำสั่งซื้อสำเร็จ</label>
                                            <DatePicker
                                                value={comdate}
                                                onChange={setComDate}
                                                renderInput={ComdateInput}
                                                minimumDate={minimumDate}
                                                maximumDate={utils().getToday()}
                                                locale={myCustomLocale}
                                                wrapperClassName='cdate col-12 p-0'
                                                calendarClassName='responsive-calendar'
                                                calendarPopperPosition='bottom'
                                                shouldHighlightWeekends
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 text-right'>
                                       <button
                                            type='button'
                                            onClick={btnSearch}
                                            className='btn btn-sm btn-primary rounded-lg'
                                        >
                                            <i className='far fa-dot-circle' /> ค้นหา
                                        </button>
                                        <button
                                            type='button'
                                            onClick={btnExport}
                                            className='btn btn-sm btn-danger rounded-lg'
                                        >
                                            <i className='fas fa-file-export' /> export
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SaleCommissionTable salelist={salelist} btnView={btnView}/>
                </div>
            )}
            <Modal show={showModal} onHide={handleClose} centered aria-labelledby='sale-detail-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='sale-detail-modal' className='col-11 p-0'>
                        รายละเอียดคอมมิชชั่น
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {saleinfo.length > 0 ? (
                            <table id='sale-detail-table' className='table table-striped table-bordered'>
                                <tr>
                                    <th>หมายเลขคำสั่งซื้อ</th>
                                    <th>รายได้</th>
                                </tr>
                                <tbody>
                                    {saleinfo.map(item => (
                                        <tr>
                                            <td>{item.order_id}</td>
                                            <td>{item.total}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            )
                        : null}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}