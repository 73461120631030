import React, { useEffect } from 'react';

import $ from 'jquery';

export function OrdersTable(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.orderlist).length > 0) {
                    if (props.orderlist.responsecode === 0) {
                        $('#orders-list-table').DataTable({
                            data: props.orderlist.info,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            dom: "<'row'<'col-sm-12 col-md-6'B><'col-sm-12 col-md-6'f>>" +
                                "<'row'<'col-sm-12'tr>>" +
                                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                            buttons: [{
                                text: 'Export',
                                className: 'btn btn-sm btn-danger rounded-lg',
                                action: function(e, dt, node, config) {
                                    props.btnExport()
                                }
                            }],
                            order: [ 1, 'desc' ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            order: false,
                            columns: [
                                {
                                    title: 'ดูรายละเอียด',
                                    className: 'dt-body-center',
                                    data: null,
                                    orderable: false,
                                    render: function(data, type, row) {
                                        return `<button
                                                type='button'
                                                class='btn btn-icon btn-primary view-order-info'
                                                data-id=${data.id}
                                                >
                                                    <i class='fa fa-search'></i>
                                            </button>`
                                        // // if (data.status === 'pending') {
                                        //     return `<button
                                        //                 type="button"
                                        //                 class="btn btn-icon btn-primary view-qrcode-info"
                                        //                 data-qrcode=${data.qr}
                                        //                 data-ref1=${data.reference1}
                                        //                 data-ref2=${data.reference2}
                                        //                 >
                                        //                     <i class="fa fa-search"></i>
                                        //             </button>`;
                                        // // } else {
                                        // //     return '';
                                        // // }
                                    }
                                },
                                {
                                    title: 'รายการที่',
                                    data: function(value) {
                                        if (value.id !== null && value.id !== '') {
                                            return value.id;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ชื่อผู้ใช้งาน',
                                    data: function(value) {
                                        if (value.username !== null && value.username !== '') {
                                            return value.username;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่ทำรายการ',
                                    data: function(value) {
                                        if (value.create_date !== null && value.create_date !== '') {
                                            return value.create_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'สถานะรายการ',
                                    className: 'dt-body-center',
                                    data: function(value) {
                                        if (value.status !== null && value.status !== '') {
                                            if (value.status === 'pending') {
                                                return '<span class="label label-light-dark label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                                            } else if (value.status === 'paid') {
                                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">ชำระเงินแล้ว</span>';
                                            } else if (value.status === 'cancel') {
                                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">ยกเลิก</span>';
                                            } else if (value.status === 'expired') {
                                                return '<span class="label label-danger label-pill label-inline mx-auto my-auto">หมดอายุ</span>';
                                            } else if (value.status === 'shipping') {
                                                return '<span class="label label-info label-pill label-inline mx-auto my-auto">กำลังจัดส่ง</span>';
                                            } else if (value.status === 'shipped'){
                                                return '<span class="label label-light-success label-pill label-inline mx-auto my-auto">จัดส่งแล้ว</span>';
                                            } else if (value.status === 'completed') {
                                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">ยืนยันสินค้าแล้ว</span>';
                                            }
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ราคา',
                                    data: function(value) {
                                        if (value.total_gross_price !== null && value.total_gross_price !== '') {
                                            return value.total_gross_price;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'วันที่ชำระเงิน',
                                    data: function(value) {
                                        if (value.paid_date !== null && value.paid_date !== '') {
                                            return value.paid_date;
                                        } else {
                                            return '-';
                                        }
                                    }
                                },
                                {
                                    title: 'ชื่อร้านค้า',
                                    data: function(value) {
                                        if (value.shop_name !== null && value.shop_name !== '') {
                                            return value.shop_name;
                                        } else {
                                            return '-';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.orderlist ]
    );

    useEffect(() => {
        $(document).ready(function() {
            $('.view-order-info').click(function() {
                props.handleShow($(this).attr('data-id'))
                // props.handleShow($(this).attr('data-qrcode'), $(this).attr('data-ref1'), $(this).attr('data-ref2'));
            });
        });
    });

    return (
        <>
            <div className='col-12 my-5'>
                <div className='card rounded-lg'>
                    <div className='card-body'>
                        {Object.keys(props.orderlist).length <= 0 ? (
                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                <div className='col-12 text-center'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {props.orderlist.responsecode !== 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-400px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>
                                                ไม่พบข้อมูลที่ต้องการค้นหาจากรายละเอียดที่ต้องการค้นหา
                                                กรุณาลองใหม่อีกครั้ง
                                            </h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        {/* <div className='row col-12 justify-content-end m-0 mb-5 p-0'>
                                            <Dropdown className='rounded-lg'>
                                                <Dropdown.Toggle
                                                    className='rounded-lg'
                                                    id='dropdown-autoclose-true'
                                                >
                                                    คำสั่งต่างๆ
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => props.btnDownload(false)}>
                                                        ดาวน์โหลดรายการจัดส่ง
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => props.btnDownload(true)}>
                                                        ดาวน์โหลดรายการจัดส่ง (Kerry Format)
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                        <div className='col-12 p-0'>
                                            <table
                                                id='orders-list-table'
                                                className='table table-striped table-bordered'
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
